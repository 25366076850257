var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"popup"},[_vm._m(0),_c('div',{staticClass:"popup__content"},[_c('h1',{class:{
          'popup__title': true,
          'loading': _vm.isLoading
        }},[(_vm.success)?_c('img',{staticClass:"popup__title-icon",attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/check-square.png","alt":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.formContent.title)+" ")]),_c('div',{staticClass:"popup__text"},[_c('p',{class:{
            'loading': _vm.isLoading,
          }},[_vm._v(" "+_vm._s(_vm.formContent.text)+" ")]),_c('p',{class:{
            'popup__text': true,
            'popup__text--color--green': true,
            'loading': _vm.isLoading,
          }},[_vm._v(" "+_vm._s(_vm.email)+" ")])]),_c('router-link',{staticClass:"popup__btn",attrs:{"to":{
          name: 'Home'
        }}},[_vm._v(" Back to Home ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"popup__header"},[_c('div',{staticClass:"popup__logo"},[_c('img',{staticClass:"popup__logo-img",attrs:{"src":"https://d1ak9kdvdopy8s.cloudfront.net/items/tcg_logo_png.png","alt":""}}),_c('span',{staticClass:"popup__logo-text"},[_vm._v(" TCG "),_c('span',{staticClass:"\n              popup__logo-text\n              popup__logo-text--color--green\n            "},[_vm._v(" World ")])])])])}]

export { render, staticRenderFns }